<template>
	<div id="body">
		<div class="fatherBox">
			<div class="headerBox">
				<p>设备列表</p>
			</div>
			<div class="query" style="display: none;">
				<div class="queryLeft">
					<div>编号：
						<el-input class="input" v-model="serchList.mechineName" size="medium" placeholder="请输入编号">
						</el-input>
					</div>
					<div>状态：
						<el-select class="select" v-model="serchList.state" @change="machineList(serchList,1)"
							size="medium" placeholder="请选择状态">
							<el-option v-for="item in stateList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<br>
					<div>地区：
						<el-select class="select" v-model="serchList.region" @change="machineList(serchList,1)"
							size="medium" placeholder="请选择地区">
							<el-option v-for="item in regionList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div>学校：
						<el-select class="select" v-model="serchList.schoolId" @change="machineList(serchList,1)"
							size="medium" placeholder="请选择学校">
							<el-option v-for="item in schoolData" :key="item.value" :label="item.schoolName"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="queryRight">
					<el-button @click="reset()">重置</el-button>
					<el-button type="primary" @click="machineList(serchList,1)">查询</el-button>
				</div>
			</div>
			<el-table :data="facilityInfos" v-loading="loading">
				<el-table-column prop="MechineName" label="设备编号" align="center" width="150"></el-table-column>
				<el-table-column label="绑定学校" :formatter="BindSchool" align="center"></el-table-column>
				<el-table-column label="设备类型" :formatter="machineFilter" align="center"></el-table-column>
				<el-table-column label="状态" sortable :formatter="Status" align="center"></el-table-column>
				<el-table-column label="在线状态" :formatter="onlineStatus" align="center"></el-table-column>
				<el-table-column label="最后时间" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.onlineTime != '0'"
							style="display: flex;align-items: center;justify-content: center;">
							<div style="width: 10px;height: 10px;border-radius: 50%;margin-right: 10px;"
								:style="scope.row.onlineStatus == '1' ? 'background: #67C23A;' : 'background: #F56C6C;'">
							</div>
							<div>{{$util.dateFormat(scope.row.onlineTime)}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="投放时间" :formatter="LaunchTime" align="center"></el-table-column>
				<el-table-column label="绑定时间" :formatter="InsertTime" align="center"></el-table-column>
				<el-table-column label="操作" fixed="right" align="center" width="140">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" plain v-if="scope.row.TeaName==null?true:false"
							@click="relieveBind(scope.$index, scope.row)">解除绑定</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="block" v-show="CountNum > 0">
				<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" :current-page="currpage"
					background layout="total,prev, pager, next, jumper" :total="CountNum" style="margin-top:20px">
				</el-pagination>
			</div>
		</div>

		<el-dialog title="解除绑定" :visible.sync="relieve" top="30px">
			<span>是否确认解除编号<p style="color:#1890FF;display:inline">{{facilityID}}</p>的设备</span>
			<div class="tels">
				<p>请输入您的手机号：</p>
				<el-input v-model.number="Moblie" style="width: 300px; float: left" placeholder="请输入手机号" maxlength="11">
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="relieve = false">取 消</el-button>
				<el-button type="primary" @click="affirm">下一步</el-button>
			</span>
		</el-dialog>

		<el-dialog title="解除绑定" :visible.sync="verification" top="30px">
			<span>手机<p style="color:#1890FF;display:inline">{{Phone}}</p></span>
			<div class="codies">
				<p>请输入验证码：</p>
				<el-input v-model="Checkcode" style="width: 300px; float: left" placeholder="请输入验证码"></el-input>
				<span class="sendyzm" @click="sendYZM" v-show="show">发送验证码</span>
				<span class="sendyzm" v-show="!show" style="color: #cfcfcf">
					{{ count + "秒" + "后重发"}}
				</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="verification = false">取 消</el-button>
				<el-button type="primary" @click="removeBind">解除绑定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		machineList,
		machineRelieve,
		schoolList
	} from '@/api/agentApi.js'
	import {
		sendSms,
    sendSmsNew,
	} from '@/api/TeacherApi.js'

	export default {
		data() {
			return {
				facilityInfos: [], // 设备列表数据
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
				serchList: {}, //查询列表
				schoolData: [], //学校集合
				stateList: [], //状态集合
				regionList: [], //地区集合
				serial: '', // 设备编码
				facilityID: '', // 设备编号
				loading: false, //加载框
				relieve: false, // 解除绑定发送短信弹窗
				Moblie: '', // 手机号
				Phone: '', // 手机号
				timer: null, // 验证码标识
				count: '',
				show: false, // 倒计时(秒)
				Checkcode: '', // 验证码
				verification: false, // 验证码弹窗
				regex: /^1[3456789]\d{9}$/, // 手机号正则
				ActiveTel: '' // 当前账号手机号
			}
		},
		mounted() {
			const newMoblie = JSON.parse(localStorage.getItem('userInfo'))
			this.ActiveTel = newMoblie.tel // 当前账号手机号
      // this.ActiveTel = 15164046768 // 测试当前账号手机号 上线要注释 开启上面的
			this.machineList({}, 1) // 获取设备列表
			this.schoolList() // 获取学校列表
		},
		methods: {
			reset() { //重置按钮
				this.serchList = {}
				this.machineList({}, 1)
			},
			LaunchTime(row, column) { // 投放时间过滤
				return row.LaunchTime
				// return row.LaunchTime.slice(0, 16)
			},
			InsertTime(row, column) { // 绑定时间过滤
				return row.InsertTime
				// return row.InsertTime.slice(0, 16)
			},
			machineList(serchJson, currpage) { // 设备列表
				this.loading = true
				const data = serchJson
				data["page"] = currpage //	当前页数
				data["limit"] = this.pagesize // 每页条数
				data["type"] = 2 // 1全部设备 2空闲设备 3非空闲设备 ( 不传默认为1 )
				machineList(data).then(res => {
					this.CountNum = parseInt(res.data.CountNum) // 总条数
					this.facilityInfos = res.data.List // 设备列表
					this.currpage = currpage //分页显示当前页
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},
			schoolList() { // 学校列表
				const data = {}
				data["page"] = 1 //	当前页数
				data["limit"] = 1000 // 每页条数
				schoolList(data).then(res => {
					this.schoolData = res.data.List.map((item, index) => {
						return Object.assign({}, {
							'schoolName': item.SchoolName
						}, {
							'id': item.ID
						})
					})
				}).catch(err => {
					console.log(err)
				})
			},
			BindSchool(row, column) { // 绑定学校
				return row.schoolName == null ? '暂无' : row.schoolName
			},
			Status(row, column) { // 状态转换
				return row.Status == '1' ? '运行中' : row.Status == '2' ? '停用中' : '故障中';
			},
			onlineStatus(row) { //在线状态过滤
				return row.onlineStatus == '1' ? '在线' : row.onlineStatus == '2' ? '离线' : '';
			},
			machineFilter(row, column) { //设备类型过滤
				switch (row.MachineType) { //设备类型 0-未设置，1-测视力，2-音视频，3-测视力和音视频, 4-校门安防设备
          case '1':
            return '测视力设备'
            break;
          case '2':
            return '音视频设备'
            break;
          case '3':
            return '测视力&音视频设备'
            break;
          case '4':
            return '校园卫士'
            break;
          case '5':
            return '智能电脑验光仪'
            break;
          case '6':
            return '音视频设备'
            break;
          default:
            return '未设置'
				}
			},
			relieveBind(index, row) { // 解除绑定
				this.relieve = true // 打开弹窗
				// console.log(row.MechineCode);
				this.facilityID = row.MechineName // 设备编号赋值
				this.serial = row.MechineCode // 设备编码赋值
			},
			affirm() { // 点击下一步
				let NewMoblie = this.Moblie
				NewMoblie += ''
				const NewTel = NewMoblie.replace(NewMoblie.substring(3, 7), '****')
				this.Phone = NewTel

				const data = {
					phone: this.Moblie,
					type: 3 //	短信类型 ( 1老师修改手机号 3代理解除设备绑定 )
				}

				if (!this.regex.test(this.Moblie)) {
					this.$message('手机号输入有误')
				} else if (this.Moblie != this.ActiveTel) {//当前手机账号和当前账号手机账号
					this.$message('当前手机号输入有误')
				} else {
					//原来发短信sendSms(data).then(res => {
            sendSmsNew(data).then(res => {
						if (res.status.code == 1) {
							this.relieve = false
							this.verification = true // 打开验证码弹窗
							this.YZMsend()
						} else {
							this.$message.error(res.status.msg)
						}
					}).catch(err => {
						console.log(err)
					})
				}
			},
			removeBind() { // 点击解除绑定
				const data = {
					mechineCode: this.serial, // 设备编码
          mechineNum: this.facilityID, // 设备编号
					code : this.Checkcode ,//	验证码
          phone: this.ActiveTel ,//	手机号
				}
				machineRelieve(data).then(res => {
					if (res.status.code == 1) {
						this.$message({
							message: '解绑成功',
							type: 'success'
						})
						this.Moblie = '' // 清空
						this.verification = false
						this.machineList({}, 1) // 刷新
					} else if(res ==7) {
						this.$message.error('请稍后重新获取验证码')
					} else {
						this.$message.error(res.status.msg)
					}
				}).catch(err => {
					console.log(err)
				})
			},
			sendYZM() { // 倒计时结束后发送验证码
				const data = {
					phone: this.Moblie,
					type: 3 //	短信类型 ( 1老师修改手机号 3代理解除设备绑定 )
				}
				// 原来sendSms(data).then(res => { // 重新开始倒计时
        sendSmsNew(data).then(res => { // 重新开始倒计时
					if (res.status.code == 1) {
						this.$message({
							message: '发送成功',
							type: 'success'
						})
						this.YZMsend()
					} else {
						this.$message.error(res.status.msg)
					}
				}).catch(err => {
					console.log(err)
				})
			},
			handleCurrentChange(val) { // 分页
				this.machineList({}, val)
				// this.currpage = val
			},
			YZMsend() { // 验证码
				const TIME_COUNT = 60
				if (!this.timer) {
					this.count = TIME_COUNT
					this.show = false
					this.timer = setInterval(() => {
						if (this.count > 0 && this.count <= TIME_COUNT) {
							this.count--
						} else {
							this.show = true
							clearInterval(this.timer)
							this.timer = null
						}
					}, 1000)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	#body {
		// height: 100%;
		background: #f0f2f5;
	}

	.fatherBox {
		width: 98%;
		// height: 800px;
		display: flex;
		background: white;
		margin: 0 auto;
		flex-direction: column;
		justify-content: center;

		.headerBox {
			display: flex;
			height: 52px;
			width: 98%;
			align-items: center;
			justify-content: space-between;
			border-bottom: 2px solid #e9e9e9;

			p {
				font-size: 16px;
				font-family: 萍方-简;
				font-weight: normal;
				color: #000000;
				margin-left: 32px;
			}

			.btn {
				display: flex;
				align-items: center;
				width: 109px;
				height: 32px;
				opacity: 1;
				border-radius: 2px;
			}
		}

		.query {
			width: 98%;
			align-items: center;
			display: flex;
			justify-content: space-between;
			padding-top: 20px;
			padding-left: 32px;

			.queryLeft {
				width: 75%;
				display: flex;

				div {
					width: 22%;
					text-align: left;

					.input,
					.select {
						width: 60%;
					}
				}
			}
		}
	}

	.tels {
		display: flex;
		width: 430px;
		margin: 0 auto;
		margin-top: 22px;
	}

	.sendyzm {
		width: 100px;
		height: 40px;
		float: left;
		cursor: pointer;
		display: inline-block;
		line-height: 40px;
		color: yellowgreen;
		border: 1px solid #cccccc;
	}

	.codies {
		display: flex;
		width: 526px;
		margin: 0 auto;
		margin-top: 16px;
	}
</style>
